import { dateToString } from '@agilox/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
} from '@angular/forms';
import { Datepickers, DatepickerType } from '@agilox/ui-common';
import { DatepickerInputComponent } from './datepicker-input/datepicker-input.component';
import { CalendarWeekOutput } from './models/calendar-week-output.interface';

@Component({
	selector: 'ui-datepicker',
	templateUrl: './datepicker.component.html',
	styleUrls: ['./datepicker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DatepickerComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => DatepickerComponent),
			multi: true,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent implements ControlValueAccessor, OnInit {
	@Input() placeholder: string = 'dd.mm.yyyy';
	@Input() showWhenValid: boolean = false;

	@Input() type: DatepickerType = Datepickers.INPUT;

	@Input() min: Date | undefined = new Date(2016, 0, 1);
	@Input() max: Date | undefined = new Date(new Date().setFullYear(new Date().getFullYear() + 10)); // today + 10 years

	@Input() syncedDate: Date | undefined;

	@Input() inputId: string = '';

	currentlyDisplayedMonth: Date = new Date();

	formControl: FormControl<string | null> = new FormControl<string>('');

	@Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();

	@Output() calendarWeekClicked: EventEmitter<CalendarWeekOutput> =
		new EventEmitter<CalendarWeekOutput>();

	/**
	 * This is sadly needed because we have no way of knowing if
	 * the formControl has been marked as touched or not by the parent.
	 * See: https://github.com/angular/angular/issues/45089
	 * @param value
	 */
	@Input() set touched(value: boolean) {
		if (value) {
			this.formControl.markAsTouched();
			return;
		}
		this.formControl.markAsUntouched();
	}

	@ViewChild(DatepickerInputComponent) input: DatepickerInputComponent | undefined;

	writeValue(value: Date | string | null): void {
		if (value instanceof Date) {
			// convert the date to yyyy-mm-dd
			value = dateToString(value);
		}

		if (value) {
			this.currentlyDisplayedMonth = new Date(value);
			this.formControl.setValue(value, { emitEvent: false });
		} else {
			this.formControl.setValue(null, { emitEvent: false });
		}
	}

	setDisabledState(isDisabled: boolean) {
		isDisabled ? this.formControl.disable() : this.formControl.enable();
	}

	onChange: any = () => {};
	onTouched: any = () => {};

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	onNavigationChange(date: Date) {
		this.currentlyDisplayedMonth = date;
	}

	ngOnInit() {
		this.formControl.valueChanges.subscribe((data: any) => {
			this.onChange(data);
			this.valueChanged.emit(data);
			this.onTouched();
		});
	}

	onDateSelectionChange(date: string) {
		this.formControl.setValue(date);
	}

	/**
	 * Do not delete, is called automatically by the form
	 */
	validate(control: AbstractControl): ValidationErrors | null {
		if (!this.formControl.validator) {
			this.formControl.setValidators(control.validator);
		}

		return null;
	}

	public openCalendar(): void {
		this.input?.openCalendar();
	}

	protected readonly Datepickers = Datepickers;
}
