import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	effect,
	EventEmitter,
	forwardRef,
	input,
	Output,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'ui-toggle',
	standalone: true,
	templateUrl: './toggle.component.html',
	styleUrls: ['./toggle.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ToggleComponent),
			multi: true,
		},
	],
})
export class ToggleComponent implements ControlValueAccessor {
	constructor(private cdr: ChangeDetectorRef) {
		effect(() => {
			if (typeof this.isOn() === 'boolean') {
				/**
				 * Type assertion is needed because the value is not guaranteed to be a boolean
				 * even if we check it in the effect, typescript will still complain:
				 * Argument of type 'boolean | undefined' is not assignable to parameter of type 'boolean'
				 */
				this.formControl.setValue(this.isOn() as boolean);
				this.cdr.markForCheck();
			}
		});
		effect(() => {
			this.setDisabledState(this.disabled());
		});
	}

	isOn = input<boolean>();
	disabled = input<boolean>(false);

	@Output() valueChanged = new EventEmitter<boolean>();

	formControl: FormControl<boolean> = new FormControl();
	onChange: any = () => {
		this.valueChanged.emit(this.formControl.value);
	};

	onTouched: any = () => {};

	writeValue(value: boolean): void {
		this.formControl.setValue(value);
		this.cdr.markForCheck();
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		isDisabled
			? this.formControl.disable({ emitEvent: false })
			: this.formControl.enable({ emitEvent: false });
		this.cdr.markForCheck();
	}

	toggle(event: Event) {
		event.preventDefault();
		event.stopImmediatePropagation();
		event.stopPropagation();
		if (this.formControl.disabled) {
			return;
		}
		this.formControl.setValue(!this.formControl.value);
		this.valueChanged.emit(this.formControl.value);
		this.onTouched();
		this.onChange(this.formControl.value);
	}
}
